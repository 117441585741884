import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import config from "../components/config/config";

import styles from "./fileUpload.module.css";

const ImportFormExcelContacts = () => {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentCopied, setCommentCopied] = useState(false);

  const copyCommentToClipboard = () => {
    /* excel colunm Last Name       First Name       Title       Phone       Address       Discount\n*/
    const commentText = `Saved As	Client Number	Email	Tel	Discount	BTW Amount	position	name	contactPerson	notes	btwNumber	showTransportCode	address	city	country	zipCode	deliveryAddress	deliveryCity	deliveryCountry	deliveryZipCode`;

    navigator.clipboard.writeText(commentText).then(() => {
      setCommentCopied(true);
      setTimeout(() => setCommentCopied(false), 3000); // Reset copied state after 3 seconds to make the message disappear
    });
  };

  const currentYearMonthMinutesSeconds = new Date();

  const year = currentYearMonthMinutesSeconds.getFullYear();
  const month = currentYearMonthMinutesSeconds.getMonth() + 1; // Months are 0-based (0 = January, 11 = December)
  const minutes = currentYearMonthMinutesSeconds.getMinutes();
  const seconds = currentYearMonthMinutesSeconds.getSeconds();

  const handleFileUpload = (e) => {
    // check if the file is an Excel file (.xls or .xlsx) and alert if not
    const fileType = e.target.files[0].type;
    if (
      fileType !== "application/vnd.ms-excel" &&
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alert("Please upload an Excel file.");
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0]; // Assuming you want the first sheet

      const sheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      console.log(jsonData);

      // Find the index of the "טיטל" key in the first row
      const titleIndex =
        jsonData.length > 0 ? Object.keys(jsonData[0]).indexOf("טיטל") : -1;

      // Move the "טיטל" column to the front of each row
      if (titleIndex !== -1) {
        jsonData.forEach((row) => {
          const keys = Object.keys(row);
          const titleValue = row["טיטל"];
          keys.splice(titleIndex, 1);
          keys.unshift("טיטל");
          row["טיטל"] = titleValue;
        });
      }

      /*   const processedContacts = jsonData.map((item) => ({
        lastName: item["משפחה "] || item["משפחה"] || item["Last Name"],
        firstName: item["ערשטע נאמען"] || item["שם פרטי"] || item["First Name"],
        title: item["טיטל"] || item["Title"],
        phone: item["טלפון"] || item["מאבייל"] || item["Phone"],
        address: item["כתובת"] || item["Address"] || item["אדרעס"],
        discount:
          item["הנחה"] ||
          item["Discount"] ||
          item["Korting"] ||
          item["discount"],
        btwAmount: item["מעס"] || item["BTW Amount"] || item["BTW Bedrag"],
        btwNumber: item["מספר מעס"] || item["BTW Number"] || item["BTW Nummer"],
      })); */

      const processedContacts = jsonData.map((item) => ({
        // ? split the name and the last part should be the first part then the rest
        saveAs: item["Saved As"]
          ? item["Saved As"]
          : item["name"]
          ? `${item["name"].split(" ").slice(1).join(" ")} ${
              item["name"].split(" ")[0]
            }`
          : "unknown",
        /*    lastName: item["משפחה "] || item["משפחה"] || item["Last Name"],
        firstName: item["ערשטע נאמען"] || item["שם פרטי"] || item["First Name"],
        title: item["טיטל"] || item["Title"], */
        phone: item["טלפון"] || item["מאבייל"] || item["Phone"] || item["Tel"],
        address:
          item["כתובת"] || item["Address"] || item["אדרעס"] || item["address"],
        discount:
          item["הנחה"] ||
          item["Discount"] ||
          item["Korting"] ||
          item["discount"],
        clientNumber: item["Client Number"],
        btwAmount: item["מעס"] || item["BTW Amount"] || item["BTW Bedrag"],
        btwNumber:
          item["מספר מעס"] ||
          item["BTW Number"] ||
          item["BTW Nummer"] ||
          item["btwNumber"] ||
          "",
        email: item["Email"] || "",
        position: item["position"] || "",
        name: item["name"] || "",
        contactPerson: item["contactPerson"] || "",
        notes: item["notes"] || "",
        showTransportCode:
          item["showTransportCode"] === "Hide" ? false : true || false,
        city: item["city"] || "",
        country: item["country"] || "",
        zipCode: item["zipCode"] || "",
        deliveryAddress: item["deliveryAddress"] || "",
        deliveryCity: item["deliveryCity"] || "",
        deliveryCountry: item["deliveryCountry"] || "",
        deliveryZipCode: item["deliveryZipCode"] || "",
      }));

      setData(processedContacts);

      // send to the server
      fetch(`${config.URL_DEV}/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(processedContacts),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // Read the file as a binary string
    reader.readAsBinaryString(file);

    // redirect to the ContactsView page after the file was uploaded deleyed by 0.5 seconds
    setTimeout(() => navigate("/ContactsView"), 500);
  };

  return (
    <div
      style={{
        justifyContent: "space-around",
        marginTop: "8rem",
        marginLeft: "22rem",
        marginRight: "2rem",
      }}
    >
      <h1>Import Excel Contacts</h1>
      <br />
      <br />
      <h4>
        <b>Copy the following Header to the Excel file top row:</b>
      </h4>
      <button
        onClick={copyCommentToClipboard}
        style={{
          backgroundColor: commentCopied ? "green" : "blue",
          color: "white",
        }}
      >
        Copy Header
      </button>
      {commentCopied && <p>Header copied to clipboard!</p>}
      <br />
      <br />
      <br />
      <br />
      <h4>
        <b>Make sure the Excel file has the following columns:</b>
      </h4>
      <ul>
        <li>Saved As</li>
        <li>Client Number</li>
        <li>Email</li>
        <li>Tel</li>
        <li>Discount</li>
        <li>BTW Amount</li>
        <li>position</li>
        <li>name</li>
        <li>contactPerson</li>
        <li>notes</li>
        <li>btwNumber</li>
        <li>showTransportCode</li>
        <li>address</li>
        <li>city</li>
        <li>country</li>
        <li>zipCode</li>
        <li>deliveryAddress</li>
        <li>deliveryCity</li>
        <li>deliveryCountry</li>
        <li>deliveryZipCode</li>
      </ul>
      <br />
      <h3>
        <b>OR</b>
      </h3>
      <br />
      <h4>
        <b>Must Have the following details</b>
      </h4>
      <ul>
        <li>name</li>
        <li>Client Number</li>
        <li>Save As</li>
      </ul>
      <br />
      <button
        style={{
          // center of the page
          position: "absolute",
          left: "80%",
          top: "30%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <input
          type="file"
          onChange={handleFileUpload}
          className={styles.uploadExcelFile}
        />
      </button>

      <br />
      <br />

      <br />

      <table className="table table-striped">
        <thead>
          <tr>
            {data.length > 0 &&
              Object.keys(data[0]).map((header, index) => (
                <th key={index}>{header}</th>
              ))}
          </tr>
        </thead>
        <tbody>{/* data details*/}</tbody>
      </table>

      {loading && <p>Loading...</p>}
    </div>
  );
};

export default ImportFormExcelContacts;
