import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../config/config";

import "./Login.css"; // Import the CSS file for the Login component

const addUser = {
  name: "",
  email: "",
  password: "",
};
// ever time load the page, fetch the user data from the server
/* const fetchUserData = async () => {
  const response = await fetch(`${config.URL_PROD}/auth/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(addUser),
  });
  const data = await response.json();
  return data;
}; */

/* fetchUserData().then((data) => console.log(data)); */

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleErrorMessage = () => {
    // Clear the error message
    setErrorMessage("");
  };

  const handleSuccessMessage = () => {
    // Clear the success message
    setMessage("");
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(`${config.URL_DEV}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        if (!response.ok) {
          console.log(response);
          throw new Error("Invalid credentials");
        }
        return response.json();
      })
      .then((result) => {
        if (result.token) {
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result));
          // redirect to the dashboard page
          navigate("/dashboard");
        }

        if (result.error || result.message) {
          setErrorMessage(
            result.error
              ? result.error.message
              : Object.values(result.message).join("")
          );
          setMessage("");
          return;
        }

        setMessage("Logged in successfully!!");
        setErrorMessage("");
        // redirect to the dashboard page
        navigate("/dashboard");
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(
          "An error occurred while processing your request: " + error.message
        );
        setMessage("");
      });
  };

  return (
    <div>
      {errorMessage && (
        <div className="error-message-container">
          <div className="error-message">
            <p>{errorMessage}</p>
            <button
              className="error-message-button"
              onClick={handleErrorMessage}
            >
              X
            </button>
          </div>
        </div>
      )}
      {message && (
        <div className="success-message-container">
          <div className="success-message">
            <p>{message}</p>
            <button
              className="success-message-button"
              onClick={handleSuccessMessage}
            >
              X
            </button>
          </div>
        </div>
      )}

      <div className="login-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <p>
          Don't have an account? <Link to="/CreateUser">Create one</Link>.
        </p>
      </div>
    </div>
  );
}

export default Login;
